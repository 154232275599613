<template>
  <div class="staff height1">
    <!--      头部      -->
    <bread></bread>
    <div class="iq-top-navbar">
      <el-page-header @back="goBack" content="测试记录"> </el-page-header>
    </div>
    <div class="center-content">
      <div class="iq-card-body menu">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form action="#" class="searchbox" :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions0" size="small" style="margin-right: 10px"> </el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="">
                <el-input v-model="searchForm.keyword" type="text" size="small" placeholder="输入关键词搜索..." @keyup.enter.native="search" clearable></el-input>
              </el-form-item> -->
              <el-form-item>
                <el-button type="primary" size="small" @click="search" plain>搜索</el-button>
                <el-button type="primary" size="small" @click="exportFile" plain :disabled="activeName == 'bz' || activeName == 'bb'">导出报告</el-button>
                <el-badge :value="exportNum" class="item" style="margin-left: 10px">
                  <el-button type="primary" size="small" @click="exportDialog = true;exportList()" plain  :disabled="activeName == 'bz' || activeName == 'bb'">导出列表</el-button>
                </el-badge>
                <div class="box"></div>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane v-for="(menu, index) in menuList" :key="index" :label="menu.name" :name="`${menu.id.toString()}`">
            <template v-if="activeName != 'bz' && activeName != 'bb'">
              <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 40px)" :default-sort="{ order: 'descending' }" ref="multipleTable" @selection-change="handleSelectionChange" :header-cell-style="{ background: '#F5F7FC' }">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column type="index" label="编号" width="80"></el-table-column>
                <el-table-column prop="questionnaire.name" label="测试类型" align="center"> </el-table-column>
                <el-table-column sortable prop="customerName" label="测试人" align="center"> </el-table-column>
                <el-table-column sortable prop="customerPhone" label="测试人手机号" align="center"> </el-table-column>
                <el-table-column prop="customerAgeDesc" label="测试结果" align="center">
                  <template slot-scope="data">
                    <p v-if="data.row.questionnaireDetermines.length > 0">{{ data.row.questionnaireDetermines[0].name }}</p>
                    <p v-else>无结果</p>
                  </template>
                </el-table-column>
                <el-table-column sortable prop="time" label="测试时间" align="center">
                  <template slot-scope="data">
                    {{ data.row.time | timefilters }}
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="150px" align="center">
                  <template slot-scope="scope">
                    <el-button size="mini" type="primary" plain @click="toDetail(scope.row)" :disabled="scope.row.questionnaireDetermines.length == 0">详情得分</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </template>
            <template v-if="activeName == 'bz'">
              <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 40px)" :default-sort="{ order: 'descending' }" :header-cell-style="{ background: '#F5F7FC' }">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column type="index" label="编号" width="80"></el-table-column>
                <el-table-column prop="userName" label="测试人" width="150px" align="center"> </el-table-column>
                <el-table-column sortable prop="userPhone" label="测试人手机号" width="150px" align="center"> </el-table-column>
                <el-table-column prop="primarySymptomName" label="测试症状" width="100px"> </el-table-column>
                <el-table-column prop="symptoms" label="症状清单" align="center"> </el-table-column>
                <el-table-column sortable prop="diagnosisTime" label="测试时间" align="center" width="160px">
                  <template slot-scope="data">
                    {{ data.row.diagnosisTime | timefilters }}
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </template>
            <template v-if="activeName == 'bb'">
              <el-table :data="tableData" stripe style="width: 100%" height="calc(100% - 40px)" :default-sort="{ order: 'descending' }" :header-cell-style="{ background: '#F5F7FC' }">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column type="index" label="编号" width="80"></el-table-column>
                <el-table-column prop="userName" label="测试人" width="150px" align="center"> </el-table-column>
                <el-table-column sortable prop="userPhone" label="测试人手机号" width="150px" align="center"> </el-table-column>
                <el-table-column prop="primarySymptomName" label="测试疾病" width="100px"> </el-table-column>
                <el-table-column prop="symptoms" label="症状清单" align="center"> </el-table-column>
                <el-table-column sortable prop="diagnosisTime" label="测试时间" align="center" width="160px">
                  <template slot-scope="data">
                    {{ data.row.diagnosisTime | timefilters }}
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </template>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog title="得分详情" :visible.sync="dialogVisible" width="1200px">
      <el-table :data="questionnaireDetermines" style="width: 100%" height="500px" :default-sort="{ order: 'descending' }" :header-cell-style="{ background: '#F5F7FC' }">
        <el-table-column type="index" label="编号" width="80"></el-table-column>
        <el-table-column prop="name" align="center" label="名称"> </el-table-column>
        <el-table-column prop="category" align="center" label="分类"> </el-table-column>
        <el-table-column sortable prop="scores" align="center" label="得分"> </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog title="导出详情" :visible.sync="exportDialog" width="1200px">
      <div class="flex-end" style="width: 100%">
        <el-button type="primary" size="small" @click="exportList" plain>刷新数据</el-button>
      </div>
      <el-table :data="exportTasks" style="width: 100%" height="500px" :default-sort="{ order: 'descending' }" :header-cell-style="{ background: '#F5F7FC' }">
        <el-table-column type="index" label="编号" width="80"></el-table-column>
        <el-table-column prop="path" align="center" label="导出文件名称">
          <template slot-scope="scope">
            <p v-if="scope.row.statu == 1">{{ scope.row.path.split('/')[3].split('.')[0] }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="compression" align="center" label="导出文件类型">
          <template slot-scope="scope">
            <p v-if="scope.row.compression">Zip</p>
            <p v-else>PDF</p>
          </template>
        </el-table-column>
        <el-table-column prop="type" align="center" label="报告文件类型"> </el-table-column>
        <el-table-column sortable prop="statu" align="center" label="导出状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.statu == 1" type="success">导出成功</el-tag>
            <el-tag v-if="scope.row.statu == 0">进行中/排队中</el-tag>
            <el-tag v-if="scope.row.statu == -1" type="danger">导出失败</el-tag>
          </template>
        </el-table-column>
        <el-table-column sortable prop="operationTime" label="提交时间" align="center">
          <template slot-scope="data">
            {{ data.row.operationTime | timefilters }}
          </template>
        </el-table-column>
        <el-table-column sortable prop="completeTime" label="完成时间" align="center">
          <template slot-scope="data">
            {{ data.row.completeTime | timefilters }}
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150px" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.statu == 1" size="mini" type="primary" plain @click="toDownload(scope.row)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { SelfTest } from '../../../components/TestRecord/SelfTest'
import AnswerUserInquiryTest from '@/views/Home/TestRecord/AnswerUserInquiryTest.vue'
export default {
  name: 'Staff',
  components: {
    bread,
    AnswerUserInquiryTest,
  },
  data() {
    var selfTest = new SelfTest(this.TokenClient, this.Services.Healthy)
    var selfTest2 = new SelfTest(this.TokenClient, this.Services.Disease)
    return {
      SelfTestDomain: selfTest,
      SelfTestDomain2: selfTest2,
      searchForm: {
        startTime: '',
        endTime: '',
        keyword: '',
        datepicker: '',
      },
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      activeName: '0',
      menuList: [],
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      DataTotal: 0,
      tableData: [],
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      OrgStaffIdList: [],
      answerPhone: this.$route.query.answerPhone,
      dialogVisible: false,
      questionnaireDetermines: [],
      userList: [],
      exportTypes: [],
      selectData: [],
      exportNum: '',
      exportTasks: [],
      exportDialog: false,
    }
  },
  mounted() {
    this.getQuestionnaire()
    this.exportList()
  },
  methods: {
    handleClick(e) {
      this.tableData = []
      this.PageIndex = 1
      switch (this.activeName) {
        case 'bz':
            this.getBZList()
          break
        case 'bb':
            this.getBBList()
          break
        default:
          this.getZCList()
          break
      }
    },
    getQuestionnaire() {
      this.SelfTestDomain.Questionnaire(
        (res) => {
          this.menuList = []
          this.activeName = res.data[0].id.toString()
          this.menuList = [...res.data, { id: 'bz', name: '辨证论治' }, { id: 'bb', name: '辨病论治' }]
          this.getZCList()
        },
        (error) => {
          console.log('请求失败!请刷新重试:', error)
        }
      )
    },
    toDownload(row) {
      window.open(`${this.Services.Healthy}/${row.path}`, '_blank')
    },
    handleSelectionChange(val) {
      this.selectData = []
      this.selectData = val
    },
    exportFile() {
      var _this = this
      if (_this.selectData.length == 0) {
        _this.$message({
          type: 'error',
          message: '请选择要导出的报告',
        })
        return
      }
      var ids = []
      this.selectData.forEach((element) => {
        ids.push(element.id)
      })
      _this.SelfTestDomain.exportFile(
        'PDF',
        ids,
        (res) => {
          _this.$message({
            type: 'success',
            message: '导出中请稍后',
          })
          _this.exportList()
        },
        (error) => {
          console.log('请求失败!请刷新重试:', error)
        }
      )
    },
    exportList() {
      var _this = this
      _this.SelfTestDomain.ExportTasks(
        function (data) {
          _this.exportTasks = data.data
          var currentList = _this.exportTasks.filter((i) => i.statu == 0)
          _this.exportNum = currentList.length == 0 ? '' : currentList.length
        },
        function (error) {
          console.log('请求失败!请刷新重试:', error)
        }
      )
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      switch (this.activeName) {
        case 'bz':
            this.getBZList()
          break
        case 'bb':
            this.getBBList()
          break
        default:
          this.getZCList()
          break
      }
    },
    search() {
      this.PageIndex = 1
      switch (this.activeName) {
        case 'bz':
            this.getBZList()
          break
        case 'bb':
            this.getBBList()
          break
        default:
          this.getZCList()
          break
      }
    },
    getBZList() {
      // var _this = this
      // _this.tableData = []
      // _this.SelfTestDomain2.GetCustomerRecord(
      //   _this.answerPhone,
      //   _this.PageIndex,
      //   function (data) {
      //     _this.tableData = data.data.results
      //     _this.PageIndex = data.data.pageIndex
      //     _this.PageSize = data.data.pageSize
      //     _this.PageTotal = data.data.pageCount
      //     _this.DataTotal = data.data.dataTotal
      //   },
      //   function (err) {
      //     console.log(err)
      //   }
      // )
      var _this = this
      var item = _this.searchForm
      _this.tableData=[]
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.SelfTestDomain2.getUserSelfDiagnosisRecord(
        _this.answerPhone,
        0,
        item.startTime,
        item.endTime,
        item.keyword,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getBBList() {
      var _this = this
      var item = _this.searchForm
      _this.tableData=[]
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.SelfTestDomain2.getUserSelfDiagnosisRecordTCMDisease(
        _this.answerPhone,
        0,
        item.startTime,
        item.endTime,
        item.keyword,
        _this.PageIndex,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    getZCList() {
      var _this = this
      var item = _this.searchForm
      _this.tableData = []
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00'
        item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59'
      } else {
        item.startTime = ''
        item.endTime = ''
      }
      _this.SelfTestDomain.UserResultByUser(
        item.keyword,
        this.PageIndex,
        this.activeName * 1,
        this.answerPhone,
        item.startTime,
        item.endTime,
        function (data) {
          _this.tableData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.DataTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    goBack() {
      this.$router.go(-1)
    },
    toDetail(item) {
      this.dialogVisible = true
      this.questionnaireDetermines = item.questionnaireDetermines.sort((a, b) => b.scores - a.scores);
      // this.questionnaireDetermines = item.questionnaireDetermines
    },
  },
}
</script>

<style scoped>
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}
.menu /deep/ .el-tabs {
  height: calc(100% - 50px);
}

.menu /deep/ .el-tabs__content {
  height: calc(100% - 55px);
}

.menu /deep/ .el-tab-pane {
  height: 100%;
}
</style>
